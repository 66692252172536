/** @jsx jsx */
import { jsx } from '@emotion/react'
// tslint:disable-next-line:no-unused-expression
jsx
import { css } from '@emotion/react'
import * as React from 'react'
import q from 'qjuul'
import { Betterblur, Icons, styles } from '@wideassessment/waui'
import { withResizeDetector } from 'react-resize-detector'
import { useEffect, useLayoutEffect, useState } from 'react'
import { setCustomPopup } from '../../utils'
import { clearQueryParam, useQueryParamString } from 'react-use-query-param-string'

interface IOwnProps {
  history?: any
  width?: number
  height?: number
  isCompany?: boolean
}
export interface CustomPopup {
  id?: string
  element: React.ReactNode,
  position?: 'center-center' | 'bottom-right' | 'top-right' | 'bottom-left' | 'top-left' | 'center-right' | 'center-left' | 'center-top' | 'center-bottom',
  width?: string,
  maxWidth?: string,
  height?: string,
  padding?: string,
  backgroundColor?: string,
  containerPadding?: string,
  textColor?: string,
  noExit?: boolean
  onClose?: () => void,
  exitButton?: boolean
  minHeight?: string
  exitButtonSize?: string
  exitButtonColor?: string
  overlayOpacity?: number
  margin?: string
  maxHeight?: string
  withHistory?: boolean
  overlayPoEv?: React.CSSProperties['pointerEvents']
  noBlurExit?: boolean
  urlQuery?: string
}

const CustomPopup = (props: IOwnProps) => {
  const doc: any = typeof document !== 'undefined' ? document : {}
  const [isShowing, setIsShowing] = useState(false)
  const [popupQuery, setPopupQuery, _, clearQuery] = useQueryParamString('popup', '', true);
  const [customPopup, _setCustomPopup] = useState<any>(doc.customPopup)

  const { isCompany = true } = props

  const handleExit = () => {
    const { onClose = () => { }, noExit } = customPopup || {}
    if (!noExit) {
      onClose();
      setIsShowing(false)
      setCustomPopup(null)
    }
  }

  const renderExitButton = () => {
    const { exitButton, exitButtonSize } = customPopup || {}
    if (!exitButton) return null;
    return (
      <q.div po="absolute" top='30px' ri="30px" z1 co="black">
        <Icons.IconCloseBig pointer size={exitButtonSize} color={customPopup?.exitButtonColor ?? styles.color.white} handleClick={handleExit} />
      </q.div>
    )
  }

  const scrollHideControl = () => {
    const { element } = customPopup || {}
    const notShowing = !element || !customPopup || !React.isValidElement(element)
    const isFullscreen = (
      customPopup?.width === "100%" &&
      customPopup?.height === "100%" &&
      ((!customPopup?.maxWidth &&
        !customPopup?.maxHeight) || customPopup?.maxWidth === "100%" && customPopup?.maxHeight === "100%")
    ) || (props.width >= window.innerWidth && props.height >= window.innerHeight)

    document.body.parentElement.style.overflow = notShowing || !isFullscreen ? 'auto' : "hidden"
  }

  useLayoutEffect(() => {
    window.addEventListener('customPopupChange', function (e) {
      _setCustomPopup(doc.customPopup)
      if (doc?.customPopup && doc.customPopup.urlQuery) {
        setPopupQuery(doc.customPopup.urlQuery)
      } else {
        setPopupQuery('')
      }
    });
  }, [])

  useEffect(() => {
    return () => {
      setCustomPopup(null)
    }
  }, [])
  

  const {
    width = "unset",
    maxWidth = "unset",
    height = "unset",
    maxHeight = "unset",
    padding = "10px",
    containerPadding = "10px",
    backgroundColor = isCompany ? styles.color.companyDark : styles.color.primaryDark,
    element,
    minHeight,
    margin,
    position = "center-center",
    textColor = styles.color.white,
  } = customPopup || {}

  const cssContainer = css`
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100%;
      background-color: rgba(0,0,0,${customPopup?.overlayOpacity ?? 0.5});
      z-index: ${5000};
      padding: ${containerPadding};
      font-weight: unset;
      font-size: unset;
    `
  const flexProp = { [`fr${position.split('-').map((x: any) => x[0].toLowerCase()).join('')}`]: true }
  

  if (!element || !customPopup) return null;
  if (!React.isValidElement(element)) return null;
  return (
    <q.div hide={!customPopup.hasContainer} css={cssContainer} {...flexProp} id="customPopup" poEv={customPopup.overlayPoEv}>
      <Betterblur onBlur={() => !customPopup.noBlurExit && handleExit()}>
        <q.div ovAu baCo={backgroundColor} poEv={'all'} ma={margin} he={`${height}`} maxh={maxHeight} wi={`${width}`} pa={padding} co={textColor} minh={minHeight} maxw={maxWidth} fctc po="relative">
          {renderExitButton()}
          {element}
        </q.div>
      </Betterblur>
    </q.div>
  )
}
export default withResizeDetector(CustomPopup)
