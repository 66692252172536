import React from 'react';
import { useTranslation } from 'react-i18next';
import PrimaryLayout from './components/layouts/primary';

const PageContext = React.createContext({lang:"no", side:"company" as 'company' | 'candidate' , originalPath:"", post:null, apiUrl: '', _matchPath: ''});
export type IPageContext = typeof PageContext;
export type IWithPageContext<T> = T & { pageContext: React.ContextType<IPageContext> };
export type IWithChildren<T> = T & { children: React.ReactNode };

export const PageContextProvider = ({ children, pageContext } : IWithPageContext<IWithChildren<{}>>) => {
    const { i18n } = useTranslation();
    const lang = pageContext?.lang ? pageContext.lang : "no"
    if(i18n.language !== lang) i18n.changeLanguage(lang);
    //if(!pageContext.side ) pageContext.side = "candidate"
    pageContext.side = "candidate"
    if(!pageContext.post) pageContext.post = {id:-1, title:'', subtitle:'',thumbnail:'',body:'', url:''}
    return (
        <PrimaryLayout pageContext={pageContext}>
            <PageContext.Provider value={pageContext}>
                {children}
            </PageContext.Provider>
        </PrimaryLayout>
    );
};
export const usePageContext = () => React.useContext(PageContext);