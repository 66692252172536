import { CustomPopup } from "./components/model"
import { resources } from "./locale/LanguageProvider"

export  const emailIsValid = (email: string) => {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regex.test(String(email).toLowerCase())
}

export const getProfilePicture =
  (
      picUrl: string,
      width?: number,
      height?: number,
      quality?: number,
  ): string => {
    if (!picUrl) return picUrl
      const p = picUrl.split('upload')
      // tslint:disable-next-line:max-line-length
      return p[0] + `upload/${height ? `h_${height},` : ''}${width ? `w_${width},` : ''}${quality ? `q_${quality},` : ''}` + p[1]?.toLowerCase()?.substring(0, p[1].lastIndexOf('.')) + '.webp'
  }

export const setCustomPopup = ( customPopup: CustomPopup | CustomPopup['element']) => {
    var popup = (customPopup as any)?.element ? customPopup as CustomPopup : { element: customPopup } as CustomPopup;    
    (document as any).customPopup = popup
    window.dispatchEvent(new Event('customPopupChange') )
};

export const GetSignupUrl  = (key?: string, side: 'candidate' | 'company' = 'company', signupPage?: string) : string => {
    let url = 'https://app.cvlink.no'
    let path = ''
    if (typeof location !== 'undefined') {
        if (location.hostname === 'localhost') url = 'http://localhost:8080'
        if (location.hostname === 'pitch.cvlink.no') url = 'https://staging.cvlink.no'
        if (location.pathname.startsWith('/candidate')) side = 'candidate'
        path = location.pathname.replaceAll('/candidate', '').replaceAll('/company', '')
    }
    let pageName = 'home'
    const split = path.split('/').filter(x=> x && !Object.keys(resources).includes(x))
    if (split.length > 0) pageName = split[0]

    let page = '/signup'
    if (signupPage) page = signupPage

    const newUrl = `${url}${`/${page}?type=${side}&src=${key ? `${key}-` : ''}${pageName}-landing${process.env.GATSBY_DOMAIN == "gb" ? '-uk' : ''}`.replaceAll('//', '/')}`
    return newUrl
}

export const GetCountryCode = () => {
    return process.env.GATSBY_DOMAIN ?? "no"
}