/** @jsx jsx */
import { jsx, css } from '@emotion/react'
// tslint:disable-next-line:no-unused-expression
jsx

export default css`
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: 'work sans', sans-serif;
}

#menuDrop {
    z-index: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600;
    letter-spacing: 0.03rem;
    padding: 0;
    margin: 0;
}

p {
    padding: 0;
    margin: 0;
}


a {
    color:inherit;
    text-decoration: inherit; 
}

.MuiLink-underlineAlways {
    text-decoration: none !important;
}

@keyframes spring {
    0% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(0.95, 1.05) translateY(-5px);
    }
    100% {
        transform: scale(1, 1);
    }
}

.spring {
    animation: spring 1s;
    animation-timing-function: ease;
    animation-iteration-count: 1;
}
`