import i18n from 'i18next';
import translations from './translations.json';
import { PaywallTranslations } from '@wideassessment/waui';
import _ from 'lodash';

const translationsObject = _.merge( PaywallTranslations, translations )

export const defaultNS = 'translations'
export const resources =  {
  ...translationsObject,
  "nb-NO": translationsObject.no,
  "nn-NO": translationsObject.no,
} as const;

i18n.init({
  resources,
  fallbackLng: 'no',
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
});

export default i18n;