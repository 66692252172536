/** @jsx jsx */
import { jsx, css, Global } from '@emotion/react'
// tslint:disable-next-line:no-unused-expression
jsx
import * as React from 'react'
import q from 'qjuul'
import Model from '../model'
import globalStyle from './globalStyle'
import _furball from 'wa-furball'
import { useEffect } from 'react'
import { IWithPageContext } from '../../PageContext'

interface IOwnProps {
    children: any
}

const PrimaryLayout = (props: IWithPageContext<IOwnProps>) => {
    const isCompany = props.pageContext.side === 'company'
    useEffect(() => {
      globalThis.furball = _furball;
    }, [])
    

  return (
    <React.Fragment>
        <Global styles={globalStyle} />
        {isCompany && <Model />}
        {props.children}
    </React.Fragment>
  )
}

export default PrimaryLayout